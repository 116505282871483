export default [
  'autoimmune disease',
  'chronic illness',
  'chronic pain',
  'disability',
  'disabled',
  'healing',
  'health',
  'healthcare',
  'invisible illness',
  'medical literacy',
  'medical care',
  'medicine',
  'pain',
  'patient advocacy',
  'self advocacy',
  'spoonie',
  'wellness'
];